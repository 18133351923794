/* eslint-disable react/prop-types */
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';
import Icon from '@vfuk/core-icon';
import MatchMedia from '@vfuk/core-match-media';
import Paragraph from '@vfuk/core-paragraph';
import CmnLoginLogout from './LoginLogoutBtn';

const IconButton = styled.a(
  props => css`
    height: 48px;
    cursor: pointer;
    display: flex;
    padding-left: 8px;
    padding-right: 8px;
    position: relative;
    justify-content: center;
    align-items: center;
    transition: background-color 0.15s;
    &:hover {
      background-color: ${props.theme.color.monochrome2};
    }
  `
);

const Dropdown = styled.div(
  props => css`
    display: none;
    position: absolute;
    top: 100%;
    background-color: ${props.theme.color.monochrome1};
    ${props.theme.mixins.elevation(true)};
    padding: 12px 16px;
    z-index: 2;
  `
);

const Name = ({ liveness, history, name }) => {
  const containerEl = useRef(null);
  const inputEl = useRef(null);

  useEffect(() => {
    if (liveness) {
      liveness.update();
    }
  });

  const handleClickOutside = event => {
    if (containerEl.current && !containerEl.current.contains(event.target)) {
      inputEl.current.style.display = 'none';
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleClick = () => {
    inputEl.current.style.display =
      inputEl.current.style.display === 'block' ? 'none' : 'block';
  };

  return (
    <div ref={containerEl}>
      <IconButton onClick={handleClick}>
        <Icon name="admin" size={2} />
        <MatchMedia breakpoint="md" andAbove>
          <Paragraph size={3}>{name || null}</Paragraph>
        </MatchMedia>
      </IconButton>
      <Dropdown ref={inputEl}>
        <CmnLoginLogout greeting="" history={history} size="sm" />
      </Dropdown>
    </div>
  );
};

const mapStateToProps = state => ({
  name: state.authentication ? state.authentication.name : null
});

export default connect(mapStateToProps)(withRouter(Name));
