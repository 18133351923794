/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import settings from 'settings';
import styled, { css } from 'styled-components';
import IconButton from '@vfuk/core-icon-button';
import Icon from '@vfuk/core-icon';
import { logout } from '../services/Liveness';

const LoginButton = styled.a(
  (props) => css`
    background-color: ${props.theme.color.primary1};
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s;
    ${props.theme.mixins.elevation(true)};
    &:hover {
      background-color: ${props.theme.color.accent1};
    }
  `
);

const LoginLogout = ({ greeting, auth, size }) => {
  return (
    <>
      {auth && auth.name ? (
        <>
          {greeting && greeting.length ? (
            <span>
              {greeting}, {auth.name}
            </span>
          ) : null}
           <IconButton
            onClick={logout}
            appearance="alt1"
            size={size === 'sm' ? 2 : 3}
            icon={{ name: 'log-out' }}
            srText="logout button"
          />
        </>
      ) : window.REACT_APP_TENANT_ID || settings.tenantId ? (
        <LoginButton
          style={{
            height: size === 'sm' ? '32px' : '56px',
            width: size === 'sm' ? '32px' : '56px',
          }}
          href={encodeURI(`https://login.microsoftonline.com/${
            window.REACT_APP_TENANT_ID || settings.tenantId
          }/oauth2/v2.0/authorize?client_id=${window.REACT_APP_CLIENT_ID || settings.clientId}
  &response_type=code
  &redirect_uri=${settings.authUri}
  &scope=${settings.azureCoreAppScope}
  &response_mode=query
  &state=${window.location.origin}/oauthcode`)}
          rel="noopener noreferrer"
        >
          <Icon name="arrow-right" size={size === 'sm' ? 1 : 2} appearance="primary" inverse />
        </LoginButton>
      ) : (
        <span>Initializing...</span>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.authentication,
});

export default connect(mapStateToProps)(LoginLogout);
