/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import isEmpty from 'lodash.isempty';
import styled, { css } from 'styled-components';
import Grid from '@vfuk/core-simple-grid';
import Typography from '@vfuk/core-heading';
import Paragraph from '@vfuk/core-paragraph';
import Icon from '@vfuk/core-icon';
import ListGroup from '@vfuk/core-list-group';
import ListItem from '@vfuk/core-list-item';
import MatchMedia from '@vfuk/core-match-media';
import Divider from '@vfuk/core-divider';
import Image from '@vfuk/core-image';
import Vodafone from '../../assets/vodafone.svg';
import Menu from '../../assets/menu.svg';
import Sidebar from '../Sidebar';
import { changeLanguage } from '../../actions/languageActions';
import Name from '../Name';
import InactivityWarning from '../dialogs/InactivityWarning';

const AppBar = styled.div(
  (props) => css`
    background-color: ${props.theme.color.primary2};
    color: inherit;
    position: fixed;
    top: 0;
    left: auto;
    right: 0;
    width: 100%;
    display: flex;
    z-index: 10;
    flex-shrink: 0;
    flex-direction: column;
    box-shadow: ${props.theme.mixins.elevation(true)};
  `
);

const ToolBar = styled.div`
  position: relative;
  min-height: 48px;
  padding-left: 24px;
  padding-right: 24px;
`;

const IconButton = styled.a(
  (props) => css`
    border-radius: 50%;
    width: 48px;
    height: 48px;
    cursor: pointer;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    transition: background-color 0.15s;
    &:hover {
      background-color: ${props.theme.color.monochrome2};
    }
  `
);

const Badge = styled.span(
  (props) => css`
    position: absolute;
    top: 14%;
    right: 14%;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    color: ${props.theme.color.monochrome1};
    background-color: ${props.theme.color.secondary1};
    ${props.theme.mixins.respondTo.md(css`
      height: 20px;
      width: 20px;
    `)}
  `
);

const Dropdown = styled.div(
  (props) => css`
    display: none;
    position: absolute;
    top: 100%;
    background-color: ${props.theme.color.monochrome1};
    min-width: 16px;
    ${props.theme.mixins.elevation(true)};
    padding: 12px 16px;
    z-index: 2;
  `
);

const Header = ({ authentication, language, changeAppLanguage }) => {
  const [openFlyout, setOpenFlyout] = useState(false);
  const containerEl = useRef(null);
  const inputEl = useRef(null);
  const [lang, setLang] = React.useState('EN');

  const handleClickOutside = (event) => {
    if (containerEl.current && !containerEl.current.contains(event.target)) {
      inputEl.current.style.display = 'none';
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleClick = () => {
    inputEl.current.style.display =
      inputEl.current.style.display === 'block' ? 'none' : 'block';
  };

  return (
    <>
      <AppBar className="header">
        <ToolBar>
          <Grid columns={{ sm: 2 }} layout={{ sm: [4, 8], md: [6] }}>
            <Grid justify="left" verticalAlign="center">
              <IconButton
                onClick={() => setOpenFlyout(!openFlyout)}
                aria-label="menu"
              >
                <Image
                  alt="Menu"
                  sm={{
                    src: Menu,
                    width: 24,
                  }}
                />
              </IconButton>
              <div style={{ margin: '0 16px 0 8px' }}>
                <Image
                  alt="vodafone logo"
                  sm={{
                    src: Vodafone,
                    width: 24,
                  }}
                />
              </div>
              <MatchMedia breakpoint="md" andAbove>
                <Typography size={1} level={5} text="Vodafone" />
              </MatchMedia>
              <Typography
                size={1}
                level={5}
                text={authentication.ctry && `\u00A0${authentication.ctry}`}
              />
            </Grid>
            <Grid justify="right" verticalAlign="center">
              <IconButton>
                <Icon name="search" size={2} />
              </IconButton>
              <IconButton>
                <Icon name="notification" size={2} />
              </IconButton>
              <IconButton onClick={handleClick} ref={containerEl}>
                <Icon name="international" size={2} />
                <Badge>
                  <MatchMedia breakpoint="md" andAbove>
                    <Paragraph size={2} justify="center">
                      {lang}
                    </Paragraph>
                  </MatchMedia>
                  <MatchMedia breakpoint="sm">
                    <Paragraph size={1} justify="center">
                      {lang}
                    </Paragraph>
                  </MatchMedia>
                </Badge>
                <Dropdown ref={inputEl}>
                  <ListGroup>
                    {language.languages &&
                      Object.entries(language.languages).map((entry) => (
                        <>
                          <ListItem
                            onClick={() => {
                              setLang(entry[0].toUpperCase());
                              changeAppLanguage(entry[0]);
                            }}
                          >
                            {entry[1].name}
                          </ListItem>
                          <Divider />
                        </>
                      ))}
                  </ListGroup>
                </Dropdown>
              </IconButton>
              {!isEmpty(authentication) && <Name />}
            </Grid>
          </Grid>
        </ToolBar>
        {authentication.inactivityWarningShown && <InactivityWarning />}
      </AppBar>
      <Sidebar openFlyout={openFlyout} setOpenFlyout={setOpenFlyout} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeAppLanguage(language) {
    dispatch(changeLanguage(language));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
