/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Liveness from 'services/Liveness';
import Header from './header/Header';
import { render } from 'pages/renderer';
import { useSelector } from 'react-redux';
import { store } from '../store/configureStore';
import { pageMounted, pageUnmounted } from '../actions/applicationActions';
import { bgColor } from '../../src/settings.json';

const TemplatedPage = ({ pageJson, pageTitle }) => {
  const [headerHeight, setHeaderHeight] = useState(null);

  const hideHeader = useSelector(state =>
    state.config.data ? state.config.data.global.hideHeader : null
  );
  const rendered = pageJson ? render(pageJson) : null;
  useEffect(() => {
    setHeaderHeight(document.querySelector('.header')?.offsetHeight);
  });
  useEffect(() => Liveness.update(), []);
  useEffect(() => {
    store.dispatch(pageMounted({ pageTitle }));
    return () => {
      store.dispatch(pageUnmounted({ pageTitle }));
    };
  }, []);
  console.log(rendered);
  return (
    <div
      onKeyDown={() => Liveness.update(false)}
      onTouchStart={() => Liveness.update(false)}
      onScrollCapture={() => Liveness.update(false)}
      onClick={() => Liveness.update(false)}
    >
      {!hideHeader && <Header />}
      <section
        style={{
          backgroundColor: bgColor || '#f4f4f4',
          minHeight: '100vh',
          paddingTop: headerHeight
        }}
      >
        {rendered}
      </section>
    </div>
  );
};

export default TemplatedPage;
