import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import i18next from 'i18next';
import AppRouter from 'routes/AppRouter';
import { store, persistor } from 'store/configureStore';
import Liveness from 'services/Liveness';
import { ThemeProvider } from 'styled-components';
import themeWS10 from '@vfuk/core-theme-ws10';
import { ThemeProvider as MUIThemeProvider } from '@material-ui/core/styles';
import vfTheme from 'vf-comps/build/AppTheme';

import { changeLanguage, getLanguageList } from 'actions/languageActions';
import { fetchConfig } from 'actions/configActions';
import { applicationMounted } from 'actions/applicationActions';

Liveness.setStore(store);
store.dispatch(getLanguageList());
store.dispatch(fetchConfig());

themeWS10.setBaseAssetLocation('/assets/ws10/');
themeWS10.setAssetLocations('fonts', 'fonts/');
themeWS10.setAssetLocations('icons', 'icons/');
themeWS10.setAssetLocations('logos', 'logos/');
themeWS10.setAssetLocations('animations', 'animations/');

i18next
  .init({
    lng: 'en',
    resources: {},
  })
  .then((t) => {
    console.log('Languages initialized with no resources');
    store.dispatch(changeLanguage('en'));
  });

const App = () => {
  useEffect(() => {
    store.dispatch(applicationMounted());
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <MUIThemeProvider theme={vfTheme}>
          <ThemeProvider theme={themeWS10}>
            <themeWS10.globalStyles />
            <div className="App">
              <AppRouter />
            </div>
          </ThemeProvider>
        </MUIThemeProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
